import React from 'react'
import Loader from './loader'
import Header from './header'
import Navbar from './navbar'
import { Helmet } from 'react-helmet'



const Layout = ({pageTitle, children}) => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                <meta name="description" content="PragICTS provides comprehensive Cyber Security Operation Services for oraginzation of all scales." />
                <meta name="keywords" content="Cyber Security Operations Services, SecOps, CyberSecOps, Firewall, EDR, Endpoint Detection and Response, XDR, Extended Detection and Response, SOAR, Security Orchestration Automation and Response, SASE, Secure Access Service Edge, SSE, Secure Service Edge, Firewall, FWaaS, Windows Server Management, Single Sign On, Endpoint Security, Spam, Phishing, Penetration Testing, Vulnerability Assessment" />
                <meta property="og:title" content="PragICTS | Cyber Security Operations (CyberSecOps) Services" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://s3.ap-southeast-1.amazonaws.com/itops.pragicts.com/1.png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:image:type" content="image/png" />
                <meta name="twitter:card" content="summary_large_image" />

                <meta property="og:description" content="PragICTS provides comprehensive Cyber Security Operation Services for oraginzation of all scales." />
                <meta property="og:site_name" content="PragICTS" />


                <meta property="og:type" content="PragICTS" />
                <meta property="og:site_name" content="PragICTS | CloudOps Services" />
                <meta name="og:email" content="info@pragICTS.com" />
                <meta name="og:phone_number" content="00 94 76 298 8284" />
                <link rel="canonical" href="https://pragicts.com" />
                <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script> 
            </Helmet>
            <Loader />
            <div id="main">
                <Header />
                <Navbar />
                {children}
            </div>
        </>
    )
}

export default Layout
